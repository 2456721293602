<template>
    <v-app>
        <Navbar/>
            <v-main style="height: 100%;" class="pt-16">
                <v-container style="margin-top: 16px;" class="pa-0">
                        <router-view></router-view>
                </v-container>
            </v-main>
        <Footer/>
    </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
    // data() {
    //     return {
    //         screenHeight: 1400,
    //     };
    // },
    name: 'App',
    components: {
        Navbar,
        Footer,
    },
    // methods: {
    //     onScreenSizeChange(e) {
    //         this.screenHeight = screen.height;
    //         console.log(`screen height changed: ${this.screenHeight}`);
    //     },
    // },
    // created() {
    //     console.log('app created, initializing');
    //     this.screenHeight = screen.height;
    //     console.log(`screen height: ${this.screenHeight}`);
    //     window.addEventListener("resize", this.onScreenSizeChange);
    // },
    // destroyed() {
    //     window.removeEventListener("resize", this.onScreenSizeChange);
    // },
};
</script>
